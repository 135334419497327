// office-addin-react - Koppeling van Mozard met Microsoft Office
// Copyright (C) 2021-2022  Mozard BV
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import React, { useState, useEffect } from "react";
import { CommandBar, PrimaryButton } from "@fluentui/react";
import ReactModal from "react-modal";

function Nav() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const _items = [
    {
      href: "https://www.mozard.nl/mozard/!suite86.scherm0325?mVrg=7781",
      iconProps: { iconName: "Link12" },
      key: "fnvb",
      text: "Meer over Mozard",
    },
  ];

  const _farItems = [
    {
      onClick: handleOpenModal,
      iconProps: { iconName: "info" },
      key: "info",
      text: "Meer over Mozard Add-in for Office",
    },
    {
      href: "/#/",
      iconProps: { iconName: "Home" },
      key: "home",
    },
    {
      href: "/#/settings",
      iconProps: { iconName: "Settings" },
      key: "instellingen",
    },
  ];

  useEffect(() => {
    if (!localStorage.getItem("modalOpened")) {
      handleOpenModal();
      localStorage.setItem("modalOpened", true);
    }
  }, []);

  return (
    <div>
      <CommandBar
        ariaLabel="Gebruik de pijltjestoetsen links en rechts om te navigeren"
        className="nav"
        farItems={_farItems}
        items={_items}
      />
      <ReactModal isOpen={showModal} contentLabel="Minimal Modal Example">
        <h1>Wat kan je met de Mozard Add-in for Office?</h1>
        <p>
          De Mozard Add-in for Office is een handige tool waarmee gebruikers eenvoudig Word-documenten kunnen bewerken
          vanuit het zaakdossier binnen de Mozard Suite. Met deze add-in kunnen gebruikers naadloos documenten openen,
          bewerken en opslaan zonder dat ze het document hoeven te downloaden. Door het bewerken en opslaan via de
          office add-in wordt er een nieuwe versie van het document toegevoegd in het zaakdossier. Het stroomlijnt het
          bewerkingsproces en verbetert de efficiëntie van het werken met documenten binnen een zakelijke context.
        </p>

        <PrimaryButton
          className="mt-4 w-100"
          iconProps={{ iconName: "cross" }}
          onClick={handleCloseModal}
          text="Ga aan de slag"
        />
      </ReactModal>
    </div>
  );
}

export default Nav;
